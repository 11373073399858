import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { GridColDef } from '@mui/x-data-grid';
import '../index.css';
import Table from '../components/Table';
// import ExpenseModal from '../components/ExpenseModal';
import { ExpenseInterface } from '../types';
import moment from 'moment';
import ExpenseModal from '../components/ExpenseModal';
import apiClient from '../services/apiClient';

const Wrapper = styled.div`
    background-color:#f3f4f8;
    width:100%;
    min-height:100vh;
    padding:20px 20px;
    box-sizing:border-box;
    @media (max-width: 768px) {
        padding:20px 20px;
    }
`;

const CreateButton = styled.button`
    background-color:#0061FF;
    border:none;
    border-radius:10px;
    color:white;
    padding:8px 18px;
    height:40px;
    // padding: 0px 6px 0px 22px;
    font-size:15px;
    transition:250ms;
    cursor:pointer;
    &:hover{
        background-color:#0053D9;
        color:#BFDFFE;
    }
`

export default function Expenses() {
    const [isEditPanelVisible, setIsEditPanelVisible] = useState<boolean>(false);
    const [selectedExpense, setSelectedExpense] = useState<ExpenseInterface | null>(null);
    const [expenses, setExpenses] = useState<ExpenseInterface[]>([]);

    const columns: (GridColDef & { isNotSearchable?: boolean })[] = [
        { field: 'title', headerName: 'Item', width: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 2 },
        { field: 'price', headerName: 'Price', width: 80, headerClassName: "table-header", cellClassName: 'thin-text', valueGetter: (params) => Number(params.value).toFixed(2), flex: 1 },
        { field: 'expense_type', headerName: 'Type', width: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 1 },
        // { field: 'created_by', headerName: 'Created By', width: 200, headerClassName:"table-header", cellClassName:'thin-text', flex:1},
        {
            field: 'date_created', headerName: 'Date Created', width: 150, headerClassName: "table-header", cellClassName: 'thin-text', renderCell: (params) => {
                const date = params.row.backdated_expense_date || params.value;
                return moment(date).format('Do MMM YYYY');
            }, flex: 1
        },
        { field: 'date_updated', headerName: 'Last Updated', width: 150, headerClassName: "table-header", cellClassName: 'thin-text', renderCell: (params) => moment(params.value).format('Do MMM YYYY'), flex: 1 },
    ];

    useEffect(() => {
        apiClient.get('/expenses').then((response: any) => {
            setExpenses(response.data)
        })
    }, [])

    const handleRowClick = (expense: ExpenseInterface) => {
        setSelectedExpense(expense);
        setIsEditPanelVisible(true);
    }

    const showCreateExpensePanel = () => {
        setIsEditPanelVisible(true)
    }

    const hideCreateExpensePanel = (expense: ExpenseInterface | null = null) => {
        setIsEditPanelVisible(false);
        setSelectedExpense(null);

        //update this flow so i know if it's from a normal close or an actual api call
        if (expense) {
            if (expense.id) {
                setExpenses(expenses.map((p: ExpenseInterface) => {
                    if (p.id === expense.id) {
                        return expense;
                    }
                    return p;
                }))
            } else {
                setExpenses([...expenses, expense]);
            }
        }
    }



    return (
        <Wrapper>
            <Header
                title="Expenses"
                description="Create edit or delete expenses here"
                headerButtons={[<CreateButton onClick={showCreateExpensePanel}>Add New</CreateButton>]}
            />
            <Table
                columns={columns}
                objects={expenses}
                sx={{ marginTop: '50px' }}
                onRowClick={(param: any) => handleRowClick(param.row)}
                customState={{
                    sorting: {
                        sortModel: [{ field: 'date_created', sort: 'desc' }],
                    }
                }}
            />
            <ExpenseModal isVisible={isEditPanelVisible} handleHidePanel={hideCreateExpensePanel} expense={selectedExpense} />
        </Wrapper>
    )
}