import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import * as routes from '../utils/routes';
import apiClient from '../services/apiClient';
import { LoginFields } from '../types';
import { CircularProgress } from '@mui/material';
import "../login.css"


const Wrapper = styled.div`display:flex; flex-direction:row; min-height:100vh;`;
const LeftPanel = styled.div`width:50%;min-height:100%;background-color:#E5F8FF;display:flex;flex-direction:column;padding:50px; justify-content:center;
@media (max-width: 768px){ display:none; }`;
const RightPanel = styled.div`width:50%;min-height:100%;background-color:white;display:flex;flex-direction:column; align-items:center;justify-content:center;
@media (max-width: 768px){ width:100%; min-height:100vh;padding:60px 0px }`;
const RegisterForm = styled.form`width:80%;padding:30px;display:flex;flex-direction:column; align-items:center;justify-content:center;
@media (max-width: 768px){ width:95%; }`;
const FormGroup = styled.div`width:100%;display:flex;flex-direction:column;`;
const InputField = styled.input`background-color:#F3F3F3;border:none;padding:15px;border-radius:8px;margin-bottom:20px;margin-top:5px`;
const Label = styled.label`font-size:14px; padding-left:2px`;
const Button = styled.button`padding:15px;width:100%;border-radius:8px;border:none;box-shadow:1px 5px 2px 0px rgb(120 120 120 / 12%); background-color:black; color:white;cursor:pointer; margin-bottom:20px`
const Logo = styled.p`font-size:30px; margin-bottom:50px; position:absolute;top:30px; font-family: 'Noto Sans', sans-serif; font-family: 'Red Hat Display', sans-serif; color:black;`;

const initialState: LoginFields = {
    email: '',
    password: '',
}

export default function Login() {
    const [isPosting, setIsPosting] = React.useState(false);
    const [loginFields, setLoginFields] = React.useState<LoginFields>(initialState);

    const navigate = useNavigate();

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsPosting(true);

        apiClient.auth.login(loginFields)
            .then(() => {
                setIsPosting(false)
                navigate(routes.DASHBOARD);
            })
            .catch(() => {
                setIsPosting(false);
            });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("change", e.target.name, e.target.value)
        setLoginFields({ ...loginFields, [e.target.name]: e.target.value });
    };

    return (
        <Wrapper>
            <LeftPanel>
                <Logo>VendReady</Logo>
                <p style={{ fontSize: '25px', lineHeight: '40px', width: '75%' }}>Login to start tracking your inventory, manage products and suppliers</p>
            </LeftPanel>
            <RightPanel>
                <RegisterForm>
                    <span className="login-header-sm" style={{ textAlign: 'center' }}>
                        <p className="logo" style={{ fontSize: '40px', margin: '0px', textAlign: 'center' }}>VendReady</p>
                        <p style={{ textAlign: 'center', marginBottom: "100px", color: 'grey', fontSize: '15px' }}>Login to track your sales and inventory</p>
                    </span>
                    <span className="login-header-lg" style={{ textAlign: 'center' }}>
                        <p style={{ fontSize: '40px', margin: '0px' }}>Welcome!</p>
                        <p style={{ textAlign: 'center', marginBottom: "140px", }}>Login to start managing your inventory!</p>
                    </span>
                    <FormGroup>
                        <Label>Email address</Label>
                        <InputField type="email" name="email" onChange={handleChange} value={loginFields.email} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Password</Label>
                        <InputField type="password" name="password" onChange={handleChange} value={loginFields.password} />
                    </FormGroup>
                    {/* <FormBottomRow><div></div><Link to={routes.FORGOTTEN_PASSWORD}>Forgotten Password</Link></FormBottomRow> */}
                    <Button onClick={handleSubmit} style={{ width: "100%", marginTop: '40px' }} disabled={isPosting}>{isPosting ? <CircularProgress size={15} color="inherit" /> : "Login"}</Button>
                    <span style={{ fontSize: '14px', marginTop: '10px' }}>
                        Don't have an account? <Link to={routes.REGISTER} style={{ color: 'blue' }}>Register</Link>
                    </span>
                </RegisterForm>
            </RightPanel>
        </Wrapper>
    )
}