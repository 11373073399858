export default function LogoutIcon() {
    // <? xml version = "1.0" ?> <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" stroke="#374151" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></svg>
    return (

        <svg
            fill="none"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M15 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H15" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M19 12L15 8M19 12L15 16M19 12H9" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </svg>
    )
}